// 前12个月或6个月
export const getBeforeTime = (number = 12) => {
  let dataArr = [];
  let timeArr = [];
  let dateArr = [];
  let monthArr = [];
  let dateFormatArr = [];
  let data = new Date();
  data.setMonth(data.getMonth() + 1, 1); //获取到当前月份,设置月份
  for (let i = 0; i < +number; i++) {
    data.setMonth(data.getMonth() - 1); //每次循环一次 月份值减1
    let chineseM;
    let m = data.getMonth() + 1;
    m = m < 10 ? "0" + m : m.toString();
    switch (m) {
      case "01":
        chineseM = "一月";
        break;
      case "02":
        chineseM = "二月";
        break;
      case "03":
        chineseM = "三月";
        break;
      case "04":
        chineseM = "四月";
        break;
      case "05":
        chineseM = "五月";
        break;
      case "06":
        chineseM = "六月";
        break;
      case "07":
        chineseM = "七月";
        break;
      case "08":
        chineseM = "八月";
        break;
      case "09":
        chineseM = "九月";
        break;
      case "10":
        chineseM = "十月";
        break;
      case "11":
        chineseM = "十一月";
        break;
      case "12":
        chineseM = "十二月";
        break;
    }
    timeArr.push(data.getFullYear() + "" + m);
    dateArr.push(data.getFullYear() + "" + m);
    dataArr.push(data.getFullYear() + "." + m);
    dateFormatArr.push(data.getFullYear() + "/" + m + "/01");
    monthArr.push(chineseM);
  }
  let dataArray = dataArr.slice(1, dataArr.length - 1);
  dateFormatArr.reverse();

  let timeArray = [timeArr[1], timeArr[timeArr.length - 1]];
  let oneYear = dateArr.slice(0);
  return {
    dataArr: dataArray.reverse(),
    timeArray: timeArray.reverse(),
    start_time: dateFormatArr.slice(0, 1)[0],
    end_time: dateFormatArr.slice(-1)[0],
    oneYear: oneYear.reverse(),
    monthArr: monthArr.reverse()
  };
};

// 今年的本月份到1月份
export const getBeforeOneMonthTime = () => {
  let dataArrMon = [];
  let timeArrMon = [];
  let date = new Date();
  let dateArr = date.getMonth();

  for (let index = 0; index < dateArr; index++) {
    date.setMonth(date.getMonth(), 0);

    let m = date.getMonth() + 1;

    m = m < 10 ? "0" + m : m;
    timeArrMon.push(date.getFullYear() + "" + m);
    dataArrMon.push(date.getFullYear() + "年" + m + "月");
  }

  return {
    dateArr,
    dataArrMon,
    timeArrMon
  };
};
