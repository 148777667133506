// 消费价格处理
export const toConversion = (num, type) => {
  if (type === "toLocaleString") {
    return num && num ? Number((+num / 100).toFixed(2)).toLocaleString() : 0;
  } else {
    return num && num ? (+num / 100).toFixed(2) : 0;
  }
};

// 月消费量求和
export const getSumMap = arr => {
  let sum = 0;
  arr.map(function(item) {
    sum += item.money;
  });
  return toConversion(sum);
};
